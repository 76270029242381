import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/carrier'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import { useFormContext } from '../context/form.context'
import { SectionBackground } from '../components/Section'
import FormDynamic from '../components/FormDynamic'
import Tiles from '../components/Tiles'
import { pageName } from '../content/_dictonary'

const Carrier = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const { image, imgDocs } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/carrier.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      imgDocs: file(absolutePath: { regex: "/images/intros/documents.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 640
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      en: 'Send request',
      es: 'Envía tu solicitud',
      ro: 'Trimiteți cererea',
    },
    action: () => setFormOpen(true),
  }

  const buttonSecond = {
    text: {
      en: 'Read More',
      es: 'Leer más',
      ro: 'Citiți mai mult',
    },
    action: 'SCROLL',
  }

  const links = [
    { name: pageName.documents[lang], image: imgDocs, slug: 'documents' },
  ]

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        imageUrl={image.publicURL}
        url={seo.url}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
      />
      <Main data={main} h={1} />

      <SectionBackground mobile top bottomXS>
        <FormDynamic notypes override='CARRIER' />
      </SectionBackground>
      <Tiles data={links} marginMinimal />
    </Layout>
  )
}

export default Carrier
